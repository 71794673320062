import {PageTitle} from "../../../page/features/PageTitle";
import {Button, Col, Form, Row} from "react-bootstrap";
import {DefaultPage} from "../../../page/features/default";
import {useTranslation} from "react-i18next";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {Checkbox} from "../../../../shared/Components/Input/Checkbox";
import {DateField} from "../../../../shared/Components/Input/DateField";
import {CartOverview} from "./components/cartOverview";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {Address} from "./components/address";
import {
    changeProfile,
    changeDeliveryDate,
    changePaymentMethod,
    changeShippingMethod,
    createOrderPayment,
    getPaymentMethods,
    getShippingMethods,
    placeOrder,
    setAgreed,
    setCode,
    setComment,
    setHasCode,
    setHasComment
} from "../../infrastructure/slices/checkoutSlice";
import {TextField} from "../../../../shared/Components/Input/TextField";
import {Loading} from "../../../general/features/loading";
import React, {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {TileArgs} from "react-calendar/src/shared/types";

const CheckoutCard = (props: { children: JSX.Element, title: string }) => {
    const {children, title} = props;
    return <div className={'cart-totals mb-3 p-4'}>
        <h5 className={'mb-4'}>{title}</h5>
        {children}
    </div>;
}

export function CheckoutPage() {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();
    const { lang } = useParams();
    const {
        cart,
        order,
        payment_gateway,
        shipments,
        order_uuid,
        cartEmpty,
        req_dlv_date,
        cartLoaded
    } = useAppSelector((state) => state.cart);
    const {
        hasComment,
        hasCode,
        code,
        comment,
        payment_methods,
        shipping_methods,
        payment_methods_loading,
        payment_methods_loaded,
        shipping_methods_loaded,
        shipping_methods_loading,
        agreed
    } = useAppSelector((state) => state.checkout);

    const {relationData, relationLoaded, delivery_date_loading, selectedProfileId} = useAppSelector((state) => state.checkout)
    const defaultProfileId = relationData?.delivery_addresses?.find((address) => address.is_default)?.profile_id || null;

    const dlvDays = relationData.delivery_days ? Object.values(relationData.delivery_days) : [];
    if (dlvDays.length === 0) {
        dlvDays.push(
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday'
        );
    }

    const navigation = useNavigate();
    const shipping_method = shipments && shipments.length ? shipments[0].shipping_method : null;
    const payment_method = payment_gateway ?? null;

    useEffect(() => {
        if (!payment_methods_loading && (order ?? 0) > 0) {
            dispatch(getPaymentMethods({order: order}))
        }
    }, [order, lang]);

    useEffect(() => {
        if (!shipping_methods_loading && (order ?? 0) > 0) {
            dispatch(getShippingMethods({order: order}))
        }
    }, [order, lang]);

    useEffect(() => {
        if (order_uuid) {
            localStorage.setItem('placed', order_uuid);
        }
    }, [order_uuid]);

    const [showValidation, setShowValidation] = useState(false);

    const finish = async () => {
      if (!agreed || !shipping_method || !payment_method) {
        setShowValidation(true);
        return;
      }

      if (payment_method === 'ideal') {
        const paymentCreateResult = await dispatch(createOrderPayment({order: order}));
        const paymentUrl = paymentCreateResult.payload.paymentUrl;
        if (!paymentUrl) {
            console.error('Payment creation failed');
            return;
        }

        window.location.href = paymentUrl as string;
        return;
      }

        const result = await dispatch(placeOrder({order: order}));
        if ('error' in result && result.error) {
            // Handle error...
        }
        else {
            navigation(`/${lang}/cart/checkout/${order}/completed`);
        }
    }

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + (minDate.getHours() > 13 ? 2 : 1));
    while (!(dlvDays.includes(minDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()))) {
        minDate.setDate(minDate.getDate() + 1);
    }

    const checkDisabled = (args: TileArgs) => {
        const {date} = args;

        // Day of week in English.
        const day = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();

        return dlvDays.length > 0 && !dlvDays.includes(day);
    }

    const gotoAssortiment = () => {
        navigation(`/${lang}/assortment`);
    }

    const dlv = req_dlv_date ? new Date(req_dlv_date) : null;

    useEffect(() => {
        if ((!dlv || dlv.getDate() < minDate.getDate()) && !delivery_date_loading) {
            dispatch(changeDeliveryDate({date: minDate.toISOString().split('T')[0]}))
        }
    }, []);

    if (cartEmpty) {
        return  <Navigate to="/cart" replace />
    }

    const counter = cart.items ? cart.items.map(item => item.quantity).reduce((partialSum, a) => partialSum + a, 0) : 0;

    const profileId = selectedProfileId ?? (defaultProfileId ?? undefined);

    return (
        <DefaultPage className={"mt-5"}>

            <PageTitle title={t('Complete order')}/>
            <Row>
                <Col xs={12} md={6} xl={4}>
                    <CheckoutCard title={t('Billing and delivery address')}>
                        <div>
                            {relationData?.delivery_addresses && relationData.delivery_addresses.length > 0 && (
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Choose a delivery address')}</Form.Label>
                                    <Form.Select
                                        className={'delivery-address'}
                                        value={profileId}
                                        onChange={(event) => {
                                          dispatch(changeProfile({ profileId: event.target.value }));
                                        }}
                                    >
                                        {relationData.delivery_addresses.map((address, index) => (
                                          <option key={index} value={address.profile_id ?? '-1'}>
                                              {address.label}
                                          </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                            <Address selectedProfileId={profileId} />
                        </div>
                    </CheckoutCard>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <CheckoutCard title={t('Shipment')}>
                        <div>
                            {(cartLoaded && relationLoaded) && <DateField
                                label={t('Choose your delivery date')}
                                inputProps={{
                                    minDate: minDate,
                                    tileDisabled: checkDisabled
                                }}
                                onChange={(newValue) => {
                                    let d: Date | string | null = newValue ? new Date(newValue.toString()) : null;
                                    if (d) {
                                        const diff = d.getTimezoneOffset();

                                        if (diff > 0 || diff < 0) {
                                            d = new Date(d.getTime() - diff * 60000);
                                        }
                                    }

                                    dispatch(changeDeliveryDate({date: d ? d.toISOString().split('T')[0] : d}))
                                }}
                                defaultValue={dlv}
                            />}
                            <p>{t('Shipping method')}:</p>
                            <div className={'p-rel'}>
                                {(shipping_methods_loading) && <Loading/>}
                                {shipping_methods_loaded && <>
                                    {shipping_methods.map((method, delta) => <div key={delta} data-id={method.id}>
                                        <Form.Group>
                                            <Form.Check
                                              type={'checkbox'}
                                              name={`shipping_method_${method.id}`}
                                              id={`shipping_method_${method.id}`}
                                              label={method.label}
                                              checked={method.id == shipping_method}
                                              onChange={event => {
                                                if (event.target.checked) {
                                                  dispatch(changeShippingMethod({shipping_method: method.id}));
                                                }
                                              }}
                                              isInvalid={showValidation && !shipping_method}
                                              feedbackType={showValidation && !shipping_method ? 'invalid' : 'valid'}
                                              feedback={showValidation && !shipping_method ? <>{t('Please choose a shipping method')}</> : <></>}
                                            />
                                        </Form.Group>
                                    </div>)}
                                </>}
                            </div>
                        </div>
                    </CheckoutCard>
                    <CheckoutCard title={t('Payment')}>
                        <div className={'p-rel'}>
                            <p>{t("I'll pay with")}:</p>
                            {(payment_methods_loading) && <Loading/>}
                            {payment_methods_loaded && <>
                                {payment_methods.map((method, delta) => <div key={delta}>
                                    <Form.Group>
                                        <Form.Check
                                          type={'checkbox'}
                                          name={`payment_method_${method.id}`}
                                          id={`payment_method_${method.id}`}
                                          label={method.label}
                                          checked={method.id == payment_method}
                                          onChange={event => {
                                            if (event.target.checked) {
                                              dispatch(changePaymentMethod({payment_method: method.id}));
                                            }
                                          }}
                                          isInvalid={showValidation && !payment_method}
                                          feedbackType={showValidation && !payment_method ? 'invalid' : 'valid'}
                                          feedback={showValidation && !payment_method ? <>{t('Please choose a payment method')}</> : <></>}
                                        />
                                    </Form.Group>
                                </div>)}
                            </>}
                        </div>
                    </CheckoutCard>
                </Col>
                <Col xs={12} md={12} xl={4}>
                    <CheckoutCard title={t('Order ({{count}})', {'count': counter ?? 0})}>
                        <div style={{contain: "content"}} className={'p-rel'}>
                            <CartOverview/>
                            <div className={'d-none'}>
                                <Checkbox label={'Heeft u een vouchercode?'} inputProps={{checked: hasCode}}
                                          onChange={newValue => dispatch(setHasCode(newValue))}/>
                                <div style={hasCode ? {} : {display: 'none'}}>
                                    <TextField label={'Vouchercode'} inputProps={{value: code}}
                                               onChange={newValue => dispatch(setCode(newValue))}/>
                                </div>
                                <Checkbox label={'Heeft u een opmerking?'} inputProps={{checked: hasComment}}
                                          onChange={newValue => dispatch(setHasComment(newValue))}/>
                                <div style={hasComment ? {} : {display: 'none'}}>
                                    <TextField label={'Opmerking'} inputProps={{value: comment}}
                                               onChange={newValue => dispatch(setComment(newValue))}/>
                                </div>
                            </div>
                            <Form.Group>
                              <Form.Check
                                type={'checkbox'}
                                name={`delivery_terms`}
                                id={`delivery_terms`}
                                label={t('Accept the general Corny Bakers delivery terms')}
                                checked={agreed}
                                isInvalid={showValidation && !agreed}
                                feedbackType={showValidation && !agreed ? 'invalid' : 'valid'}
                                feedback={showValidation && !agreed ? <>{t('Please confirm you accept our delivery terms')}</> : <></>}
                                onChange={event => {
                                  dispatch(setAgreed(event.target.checked));
                                }}
                              />
                            </Form.Group>
                            <Button variant={'danger'} className={'mt-3 float-end'}
                                    onClick={finish}>{t('Pay order')}
                                <FaAngleRight/></Button>
                        </div>
                    </CheckoutCard>
                </Col>
            </Row>
            <Button variant={'danger'} onClick={gotoAssortiment}><FaAngleLeft/> {t('Continue shopping')}</Button>
        </DefaultPage>
    );
}

